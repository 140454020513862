import Provider from "../../../../../../provider";
import myTheme from "../../../../../../theme";
import Source from "../../../../../../source";
import Layout from "../../../../../../layouts";
import Iframe from "../../../../../../iframe";
import BeforeAfter from "../../../../../../beforeafter";
import { Green, Orange, BgImage } from "../../../../../../layouts";
import discriminator from "../../../../../../static/discriminator.png";
import realitydefender from "../../../../../../static/realitydefender.png";
import cccpfakenews from "../../../../../../static/cccpfakenews.png";
import cccpads from "../../../../../../static/cccpads.png";
import dfexample from "../../../../../../static/dfexample.gif";
import spectrum from "../../../../../../static/spectrum.png";
import data from "../../../../../../static/data.png";
import generator from "../../../../../../static/generator.png";
import game from "../../../../../../static/gen-discrim.png";
import trends from "../../../../../../static/trends.png";
import ian from "../../../../../../static/ian.png";
import neural from "../../../../../../static/neural-net.png";
import deepnudeapp from "../../../../../../static/deepnudeapp.png";
import imageabuse from "../../../../../../static/diagram-telegram-image-abuse.png";
import faketinderboy from "../../../../../../static/faketinderboy.png";
import faketindergirl from "../../../../../../static/faketindergirl.png";
import fakeinstagram from "../../../../../../static/fakeinstagram.png";
import senior from "../../../../../../static/senior.jpeg";
import dalimuseum from "../../../../../../static/dalimuseum.jpeg";
import dali from "../../../../../../static/dali.gif";
import welcometochechnya from "../../../../../../static/welcometochechnya.jpeg";
import malariamustdie from "../../../../../../static/malariamustdie.png";
import dressingroom from "../../../../../../static/dressingroom.png";
import modelfit from "../../../../../../static/modelfit.jpg";
import deepfakeactor from "../../../../../../static/deepfakeactor.png";
import fakeceo from "../../../../../../static/fakeceo.png";
import nextrembrandt from "../../../../../../static/nextrembrandt.png";
import firstaiauction from "../../../../../../static/firstaiauction.png";
import emmaholten from "../../../../../../static/emmaholten.jpeg";
import netflix from "../../../../../../static/netflix.png";
import netflixf from "../../../../../../static/netflixf.png";
import balamy from "../../../../../../static/balamy.jpeg";
import spectre from "../../../../../../static/spectre.png";
import geminiman from "../../../../../../static/geminiman.png";
import faceswap from "../../../../../../static/faceswap.png";
import dfexplainer from "../../../../../../static/dfexplainer.png";
import dfexplainergif from "../../../../../../static/out_anisa_faces.gif";
import city from "../../../../../../static/city.png";
import gabon from "../../../../../../static/gabon-president.jpg";
import one from "../../../../../../static/quiz/1.png";
import onef from "../../../../../../static/quiz/1f.png";
import two from "../../../../../../static/quiz/2.png";
import twof from "../../../../../../static/quiz/2f.png";
import three from "../../../../../../static/quiz/3.png";
import threef from "../../../../../../static/quiz/3f.png";
import four from "../../../../../../static/quiz/4.png";
import fourf from "../../../../../../static/quiz/4f.png";
import five from "../../../../../../static/quiz/5.png";
import fivef from "../../../../../../static/quiz/5f.png";
import six from "../../../../../../static/quiz/6.png";
import sixf from "../../../../../../static/quiz/6f.png";
import seven from "../../../../../../static/quiz/7.png";
import sevenf from "../../../../../../static/quiz/7f.png";
import vesselone from "../../../../../../static/quiz/vessel1.png";
import vesselonef from "../../../../../../static/quiz/vessel1f.png";
import vesseltwo from "../../../../../../static/quiz/vessel2.png";
import vesseltwof from "../../../../../../static/quiz/vessel2f.png";
import * as React from 'react';
export default {
  Provider: Provider,
  myTheme: myTheme,
  Source: Source,
  Layout: Layout,
  Iframe: Iframe,
  BeforeAfter: BeforeAfter,
  Green: Green,
  Orange: Orange,
  BgImage: BgImage,
  discriminator: discriminator,
  realitydefender: realitydefender,
  cccpfakenews: cccpfakenews,
  cccpads: cccpads,
  dfexample: dfexample,
  spectrum: spectrum,
  data: data,
  generator: generator,
  game: game,
  trends: trends,
  ian: ian,
  neural: neural,
  deepnudeapp: deepnudeapp,
  imageabuse: imageabuse,
  faketinderboy: faketinderboy,
  faketindergirl: faketindergirl,
  fakeinstagram: fakeinstagram,
  senior: senior,
  dalimuseum: dalimuseum,
  dali: dali,
  welcometochechnya: welcometochechnya,
  malariamustdie: malariamustdie,
  dressingroom: dressingroom,
  modelfit: modelfit,
  deepfakeactor: deepfakeactor,
  fakeceo: fakeceo,
  nextrembrandt: nextrembrandt,
  firstaiauction: firstaiauction,
  emmaholten: emmaholten,
  netflix: netflix,
  netflixf: netflixf,
  balamy: balamy,
  spectre: spectre,
  geminiman: geminiman,
  faceswap: faceswap,
  dfexplainer: dfexplainer,
  dfexplainergif: dfexplainergif,
  city: city,
  gabon: gabon,
  one: one,
  onef: onef,
  two: two,
  twof: twof,
  three: three,
  threef: threef,
  four: four,
  fourf: fourf,
  five: five,
  fivef: fivef,
  six: six,
  sixf: sixf,
  seven: seven,
  sevenf: sevenf,
  vesselone: vesselone,
  vesselonef: vesselonef,
  vesseltwo: vesseltwo,
  vesseltwof: vesseltwof,
  React: React
};